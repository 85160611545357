<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="حفظ"
      back-button-text="السابق"
      next-button-text="التالي"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- accoint detailstab :before-change="validationForm" -->
      <tab-content title="معلومات الزيارة " :before-change="validationForm" >
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col md="6" xl="4">
              <b-form-group label-for="phone">
                <label>تاريخ الزيارة</label>
                <flat-pickr class="form-control" v-model="visit.visitModel.visit_date" />
              </b-form-group>
            </b-col>
            <!-- <b-col md="3">
              <b-form-group label="رقم العائلة " label-for="number">
                <validation-provider
                  #default="{ errors }"
                  name="number"
                  rules="required"
                >
                  <b-form-input
                    id="c-number"
                    v-model="visit.visitModel.number"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col md="3">
              <validation-provider #default="{ errors }" rules="required">
                    <b-form-group
                      label-for="cardNumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نوع الوثيقة</label>
                      <v-select
                      v-model="visit.visitModel.document_type_id"
                      :options="optionsDocument"
                      :reduce="(val) => val.value"
                        label="name"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                    #default="{ errors }"
                    rules="regex:^([0-9]+)$|required"
                  >
                    <b-form-group
                      label-for="numberdocument"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> رقم الوثيقة</label>
                      <b-form-input 
                      v-model="visit.visitModel.document_type_number" 
                      placeholder="رقم الوثيقة" />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
            </b-col>
            <b-col md="3" xl="4">
              <validation-provider
                #default="{ errors }"
                name="family"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label-for="familyNatural"
                >
                  <label> طبيعة العائلة</label>
                  <v-select
                    v-model="visit.visitModel.family_status_id"
                    :options="FamilyStatus"
                    label="name"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="4">
              <validation-provider
                #default="{ errors }"
                name="FormType"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label-for="FormType"
                >
                  <label> نوع الاستمارة</label>
                  <v-select
                    v-model="visit.visitModel.form_type"
                    :options="FormType"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
           
            <b-col md="3">
              <b-form-group label="اسم الاب" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="father_name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="visit.visitModel.father_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="اسم الام" label-for="mother_name">
                <validation-provider
                  #default="{ errors }"
                  name="mother_name"
                  rules="required"
                >
                  <b-form-input
                    id="mother_name"
                    v-model="visit.visitModel.mother_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="عدد الاطفال" label-for="childrens_count">
                <validation-provider
                  #default="{ errors }"
                  name="childrens_count"
                  vid="childrens_count"
                  rules="required"
                >
                  <b-form-input
                    id="childrens_count"
                    v-model="visit.visitModel.childrens_count"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="عدد المسنين " label-for="elders_count">
                <validation-provider
                  #default="{ errors }"
                  name="elders_count"
                  rules="required"
                >
                  <b-form-input
                    id="c-elders_count"
                    v-model="visit.visitModel.elders_count"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
            
              <b-form-group label="رقم الاتصال " label-for="contact">
                <validation-provider
                  #default="{ errors }"
                  name="contact"
                  rules="required"
                >
                <b-form-tags
                    id="c-contact"
                    v-model="visit.visitModel.contact"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="visit.visitModel.family_status_id=='1' || visit.visitModel.family_status_id=='5'">
              <b-form-group
                label="مكان الاقامة الاصلي "
                label-for="native_place"
              >
                <validation-provider
                  #default="{ errors }"
                  name="native_place"
                  rules="required"
                >
                  <b-form-input
                    id="c-native_place"
                    v-model="visit.visitModel.native_place"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          
          <h3>مكان الإقامة الحالي</h3>
          <br />
          <b-row>
            <b-col md="6" xl="3">
              <validation-provider
                
                name="city"
                rules="required"
              >
                <b-form-group
                  label-for="city"
                
                >
                  <label> المحافظة</label>
                  <b-form-input v-model="selectedCity.label" readonly />
                </b-form-group>
                <b-form-invalid-feedback >
                 
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider
                
                name="area"
                rules="required"
              >
                <b-form-group
                  label-for="area"
                
                >
                  <label> المنطقة</label>
                  <v-select
                    v-model="selectedArea"
                    :reduce="(val) => val.value"
                    :options="optionCity"
                  />
                </b-form-group>
                <b-form-invalid-feedback >
                 
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider
                
                name="village"
                rules="required"
              >
                <b-form-group
                  label-for="village"
                
                >
                  <label> الناحية</label>
                  <v-select
                    v-model="visit.current_address.town_id"
                    :reduce="(val) => val.value"
                    :options="optionVillages"
                    @input="getTown(visit.current_address.town_id)"
                  />
                </b-form-group>
                <b-form-invalid-feedback >
                 
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider  name="town" rules="">
                <b-form-group
                  label-for="village"
                
                >
                  <label> الحي</label>
                  <v-select
                    v-model="visit.current_address.neighborhood_id"
                    :reduce="(val) => val.value"
                    :options="optionTown"
                    @input="getoptionStreet(visit.current_address.neighborhood_id)"

                  />
                </b-form-group>
                <b-form-invalid-feedback >
                 
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              xl="2"
            >
              <b-form-group label-for="newtown">
                <label> تفاصيل الحي</label>
                <v-select
                  id="input-default"
                  v-model="visit.current_address.street_id"
                  :reduce="(val) => val.value"
                  :options="optionStreet"
                />
              </b-form-group>
            </b-col>
<!-- delete Type of residence -->
            
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="نوع الاقامة " label-for="residence_type_id" >
                <validation-provider
                  #default="{ errors }"
                  name="residence_type_id"
                  rules="required"
                >
                 
                  <v-select
                    v-model="visit.current_address.residence_type_id"
                    :reduce="(val) => val.value"
                    :options="ResidenceTypes"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="تفاصيل الاقامة "
                label-for="residence_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="residence_description"
                  rules="required"
                >
                  <b-form-input
                    id="c-residence_description"
                    v-model="visit.current_address.description"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="idea">
                <validation-provider
                  #default="{ errors }"
                  name="idea"
                  rules="required"
                >
                  <label>
                    هل بوجد لدى احد افراد الاسرة فكرة واعدة لاعداد مشروع يساعد
                    من دخل الاسرة</label
                  >
                  <v-select
                    v-model="visit.visitModel.idea"
                    :reduce="(val) => val.id"
                    :options="ideaOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="visit.visitModel.idea == 1">
              <b-form-group label="نوع الفكرة " label-for="idea_type">
                <validation-provider
                  #default="{ errors }"
                  name="idea_type"
                  rules="required"
                >
                  <v-select
                    v-model="visit.visitModel.idea_type"
                    :reduce="(val) => val.id"
                    :options="ideaTypes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="visit.visitModel.idea == 1">
              <b-form-group label="تفاصيل الفكرة " label-for="idea_description">
                <validation-provider
                  #default="{ errors }"
                  name="idea_description"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="visit.visitModel.idea_description"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3" xl="4">
              <validation-provider
                #default="{ errors }"
                name="FormType"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label-for="FormType"
                >
                  <label>  المتطوع</label>
                  <v-select
                    v-model="visit.visitModel.volunteer_id"
                    :options="volunteerOption"
                    label="name"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="4">
              <b-form-group label-for="notes">
                <label>الملاحظات المتطوع</label>
                <b-form-textarea
                  id="textarea-volunteer_notes"
                  v-model="visit.visitModel.volunteer_notes"
                  placeholder="الملاحظات"
                  rows="2"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- personal details tab :before-change="validationFormInfo"     -->
      <tab-content
        title="نقاط الضعف  "
        :before-change="validationFormInfo"
      >
        <b-modal
          ref="my-modal"
          centered
          cancel-title="No"
          cancel-variant="outline-secondary"
          title-tag="div"
        >
          <validation-observer ref="phonesimple">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label=" الاسم الثلاثي"
                    label-for="-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name=" الاسم الثلاثي"
                      rules="required"
                    >
                      <b-form-input
                        id="-name"
                        v-model="newWeakness.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="6"
                >
                  <b-form-group label-for="phone">
                    <label>تاريخ الميلاد</label>
                    <flat-pickr
                      v-model="newWeakness.birth_date"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="select"
                    rules="required"
                  >
                    <b-form-group
                      label-for="gender"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الجنس</label>
                      <v-select
                        v-model="newWeakness.gender"
                        :reduce="(val) => val.id"
                        :options="optiongender"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="12"
                  xl="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="weakname"
                    :rules="{ subOptionRequired: { optionsWeak: optionsWeak } }"
                  >
                    <b-form-group
                      label-for="weakname"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نقاط الضعف</label>
                      <treeselect
                        v-model="newWeakness.weakness"
                       
                        :options="optionsWeak"
                        :multiple="false"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="12"
                  xl="12"
                >
                  <b-form-group label-for="delimiter">
                    <label> الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="newWeakness.notes"
                      placeholder="الملاحظات"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col class="but" md="4" xl="2" v-if="weak.id">
                  <b-button variant="edit" @click="updateBenWek(weak.id)">
                    <span class="align-middle"> تعديل النقطة</span>
                  </b-button>
                </b-col> -->
              </b-row>
            </b-form>
          </validation-observer>
          <template #modal-footer>
            <b-button
              v-if="updateWeaknessModel"
              variant="edit"
              @click="updateBenWek(newWeakness)"
            >
              <span class="align-middle"> تعديل النقطة</span>
            </b-button>
            <b-button
              v-else
              variant="outline-primary"
              @click="addnewWeakness"
            >
              <span class="align-middle"> إضافة </span>
            </b-button>
          </template>
        </b-modal>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-table
            responsive="sm"
            :items="visit.weaknessModel"
            :fields="fieldsWeak"
            show-empty
            empty-text="لا يوجد نقاط ضعف"
          >
            <template
              v-if="visit.weaknessModel.length > 0"
              #cell(gender)="data"
            >
              {{ optiongender.find((el)=> el.id==data.item.gender).label }}
            </template>
            <template               
            v-if="visit.weaknessModel.length > 0"               
            #cell(weakness)="data"             >               
            {{ subWeakness.find((el)=> el.id==data.item.weakness).name }}             </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item-button @click="showWeakness(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="deleteWeakness(data.item)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </template>
          </b-table>
          <b-row>
            <b-col xl="4">
              <b-button
                variant="outline-primary"
                @click="addWeak()"
              >
                <span class="align-middle"> إضافة نقطة ضعف اخرى </span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- address :before-change="validationFormAddress" -->
      <tab-content
        title="التحويلات"
        :before-change="validationFormAddress"
      >
        <b-modal
          ref="my-modal-referral"
          centered
          cancel-title="No"
          cancel-variant="outline-secondary"
          title-tag="div"
        >
          <validation-observer ref="phonesimple">
            <b-form>
              <b-row>
                <b-col md="12">
              
                <b-form-checkbox
                  v-model="oldPerson"
                  switch
                inline
                  name="oldperson"
                  class="custom-control-success"
               
                >
                  <label> فرد مدخل مسبقا</label>
                </b-form-checkbox>
           
                  </b-col>
                  <template v-if="oldPerson">
                  <b-col md="12" >
                  <b-form-group
                    label=" الاسم الثلاثي"
                    label-for="-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name=" الاسم الثلاثي"
                      rules="required"
                    >
                     
                   
                      <v-select
                        v-model="newRefeeral.name"
                        :reduce="(val) => val.name"
                        label="name"
                        @input="selectDetails(newRefeeral.name)"
                        :options="visit.weaknessModel"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="6"
                >
                  <b-form-group label-for="phone">
                    <label>تاريخ الميلاد</label>
                    <flat-pickr
                    disabled
                      v-model="newRefeeral.birth_date"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="select"
                    rules="required"
                  >
                    <b-form-group
                      label-for="gender"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الجنس</label>
                      <v-select
                      disabled
                        v-model="newRefeeral.gender"
                        :reduce="(val) => val.id"
                        :options="optiongender"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </template>
                <template v-else>
                <b-col md="12">
                  <b-form-group
                    label=" الاسم الثلاثي"
                    label-for="-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name=" الاسم الثلاثي"
                      rules="required"
                    >
                      <b-form-input
                        id="-name"
                        v-model="newRefeeral.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="6"
                >
                  <b-form-group label-for="phone">
                    <label>تاريخ الميلاد</label>
                    <flat-pickr
                      v-model="newRefeeral.birth_date"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="select"
                    rules="required"
                  >
                    <b-form-group
                      label-for="gender"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الجنس</label>
                      <v-select
                        v-model="newRefeeral.gender"
                        :reduce="(val) => val.id"
                        :options="optiongender"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </template>
                <b-col
                  md="12"
                  xl="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="service"
                    rules="required"
                  >
                    <b-form-group
                      label-for="weakname"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الخدمة</label>
                      <v-select
                        v-model="newRefeeral.main_service_id"
                        :reduce="(val) => val.value"
                        :options="optionService"
                        @input="getSubService(newRefeeral.main_service_id)"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="12"
                  xl="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="documentType"
                    rules="required"
                  >
                    <b-form-group
                      label-for="cardNumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الخدمة الفرعية </label>
                      <v-select
                        v-model="newRefeeral.sub_service_id"
                        :reduce="(val) => val.value"
                        :options="optionSubServices"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="12"
                  xl="12"
                >
                  <b-form-group label-for="delimiter">
                    <label> الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="newRefeeral.notes"
                      placeholder="الملاحظات"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col class="but" md="4" xl="2" v-if="weak.id">
                  <b-button variant="edit" @click="updateBenWek(weak.id)">
                    <span class="align-middle"> تعديل النقطة</span>
                  </b-button>
                </b-col> -->
              </b-row>
            </b-form>
          </validation-observer>
          <template #modal-footer>
            <b-button
              v-if="updateReferralModel"
              variant="edit"
              @click="updateReferral(newRefeeral)"
            >
              <span class="align-middle"> تعديل الحالة</span>
            </b-button>
            <b-button
              v-else
              variant="outline-primary"
              @click="addnewReferral"
            >
              <span class="align-middle"> إضافة </span>
            </b-button>
          </template>
        </b-modal>
        <b-table
          responsive="sm"
          :items="visit.referralModel"
          :fields="fields"
          show-empty
          empty-text="لا يوجد  تحويلات"
        >
          <template
              v-if="visit.referralModel.length > 0"
              #cell(gender)="data"
            >
              {{ optiongender.find((el)=> el.id==data.item.gender).label }}
            </template>
            <template
              v-if="visit.referralModel.length > 0"
              #cell(main_service_id)="data"
            >
              {{ optionService.find((el)=> el.value==data.item.main_service_id).label }}
            </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item-button @click="showReferral(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">تعديل</span>
              </b-dropdown-item-button>
              <b-dropdown-item-button @click="deleteRefferal(data.item)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <validation-observer
          ref="addressRules"
          tag="form"
        >
      
          <b-row>
            <b-col
              xl="4"
              class="mt-5"
            >
              <b-button
                variant="outline-primary"
                @click="addreferral()"
              >
                <span class="align-middle"> إضافة حالة  </span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- social link -->
      <tab-content
        title="الاسئلة"
        :before-change="validationFormSocial"
      >
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-row v-for="(item, i) in visit.evaluationModel">
            <b-col md="4" v-if="item.status == 1 && item.key !== 'other_needs'">
              <b-form-group
                label=""
                label-for="-name"
              >
                <label>{{ item.label }}</label>
              </b-form-group>
            </b-col>
            <b-col md="4" v-else>
              <b-form-group
                label=""
                label-for="-name"
              >
              {{ item.label }}
              
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              xl="2"
            >
              <validation-provider
                #default="{ errors }"
                name="select"
                rules="required"
              >
                <b-form-group
                  label-for="optionstatus"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الاجابة</label>
                  <v-select
                    v-model="item.status"
                    :reduce="(val) => val.id"
                    :options="optionstatus"
                  />
                </b-form-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              v-if="item.status == 1  && item.key == 'other_needs'"
              md="6"
              xl="4"
            >
              <b-form-group label-for="notes">
                <label>الملاحظات</label>
             
                <v-select
                      v-model="item.discription"
                      
                     
                      :options="optionlabel"
                     
                    />
              </b-form-group>
            </b-col>
            <b-col
              v-else
              md="6"
              xl="4"
            >
              <b-form-group label-for="notes">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-volunteer_notes"
                  v-model="item.discription"
                  placeholder="الملاحظات"
                  rows="2"
                />
               
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { location } from "@/@core/mixins/location.js";
import {
  required, email, max, is_earlier,
} from '@validations'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import flatPickr from 'vue-flatpickr-component'
import Vue from 'vue';
import 'flatpickr/dist/flatpickr.css'
import {
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTags,
  BFormTextarea,
  BButton,
  BModal,
  BFormCheckbox,
  BForm,
  BDropdown,
  BDropdownItemButton
} from 'bootstrap-vue'
export default {
  components: {
    required,
    Treeselect,
    BButton,
    flatPickr,
    BFormTags,
    BFormTextarea,
    email,
    BModal,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BTable,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BForm,
  BDropdown,
  BDropdownItemButton
  },
    mixins: [location],
  data() {
    return {
      FamilyStatus: [],
      fieldsWeak: [
        { key: 'name', label: ' الاسم الثلاثي' },
        { key: 'birth_date', label: ' تاريخ الميلاد' },
        { key: 'weakness', label: ' نقطة الضعف' },
        { key: 'gender', label: 'الجنس' },
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },
      ],
      fields: [
        { key: 'name', label: ' الاسم الثلاثي' },
        { key: 'birth_date', label: ' تاريخ الميلاد' },
        { key: 'main_service_id', label: ' الخدمة ' },
        { key: 'gender', label: 'الجنس' },
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },
      ],
      optionsWeak: [],
      ResidenceTypes: [],
      optionlabel: [],
      newRefeeral:{},
      FormType: [
        {
          id: 0,
          label: 'فرد',
        },
        {
          id: 1,
          label: 'عائلة',
        },
      ],
      optionsWeakAll:[],
      optionstatus: [
        {
          id: 0,
          label: 'لا',
        },
        {
          id: 1,
          label: 'نعم',
        },
      ],
      optiongender: [
        {
          id: 2,
          label: 'انثى',
        },
        {
          id: 1,
          label: 'ذكر',
        },
      ],
      ideaTypes: [
        {
          id: 0,
          label: 'تجارية',
        },
        {
          id: 1,
          label: 'صناعية',
        },
        {
          id: 2,
          label: 'خدمة',
        },
        {
          id: 3,
          label: 'زراعية',
        },
      ],
      ideaOption: [
        {
          id: 0,
          label: 'لا',
        },
        {
          id: 1,
          label: 'نعم',
        },
      ],
      oldPerson:false,
      optionsDocument:[],
      visit: {
           current_address: {
        neighborhood_id: "",
        street_id:"",
        town_id: "",
        residence_type_id: "",
        description: "",
      },
        visitModel: {
       
          visit_date: '',
          family_status_id: '',
          father_name: '',
          mother_name: '',
          childrens_count: '',
          elders_count: '',
          document_type_id: '',
          document_type_number:'',
          form_type: '',
          volunteer_id:'',
       
          contact: [],
          native_place: '',
          // residence_type_id: '',
          // residence_description: '',
          idea_description: '',
          idea: '',
          idea_type: '',
          volunteer_notes: '',
        },
        weaknessModel: [
        ],
        referralModel: [
          
        ],
        evaluationModel: [
          {
            key: 'woman_at_risk',
            label: 'هل يوجد حالات تعنيف للمرأة ؟',
            status: 1,
            discription: '',
          },
          {
            key: 'child_at_risk',
            label: 'هل يوجد حالات تعنيف للاطقال ؟',
            status: 1,
            discription: '',
          },
          {
            key: 'socity_at_fear',
            label: 'هل يوجد حالات خوف من المجتمع  ؟',
            status: 1,
            discription: '',
          },
          {
            key: 'family_brakUpCont',
            label: 'هل يوجد حالات تفكك اسري  ؟',
            status: 1,
            discription: '',
          },
          {
            key: 'risks',
            label: 'هل يوجد حالات خطر واضحة ؟',
            status: 1,
            discription: '',
          },
          {
            key: 'other_needs',
            label: 'ما هي احتياجات الأسرة؟',
            status: 1,
            discription: '',
          },
        ],
      },
      newWeakness: {},
      subWeakness:[],
      volunteerOption:[],
      optionStreet:[],
      updateWeaknessModel: false,
      updateReferralModel: false,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      optionService: [],
      optionSubServices: [],
    }
  },
  created() {
    this.getFamilyStatus()
    this.getServiceType()
    this.getOptionsDocument()
  },
  methods: {
    removeweaknessModel(index) {
      this.visit.weaknessModel.splice(index, 1)
    },
    getSubService(id) {
      this.optionSubServices = []
      const url = `/api/v1/main_services/${id}/proposal_services`
      this.$http.get(url).then(res => {
        const subServices = res.data.data
        subServices.forEach(el => {
          this.optionSubServices.push({ label: el.name, value: el.id })
        })
      })
    },
    getOptionsDocument() {
      this.optionsDocument = []
      const url = `/api/v1/document_types`
      this.$http.get(url).then(res => {
        // console.log(res.data.data)
        const subServices = res.data.data
        subServices.forEach(el => {
          
          this.optionsDocument.push({ name: el.name, value: el.id })
        
        })
      })
      // console.log("this.optionsDocument" + this.optionsDocument)
    },
    getServiceType() {
      this.$http
        .get('/api/v1/beneficiaries_page/get_dropdown_data')
        .then(res => {
          const proposal_services = res.data.data.main_services
          proposal_services.forEach(el => {
            this.optionService.push({ label: el.name, value: el.id })
          })
        })
        this.$http
        .get('/api/v1/volunteer')
        .then(res => {
          this.volunteerOption = res.data
         
        })
        
    },
    addweaknessModel() {
      this.visit.weaknessModel.push({
        name: '',
        gender: null,
        birth_date: '',
        weakness: '',
        notes: '',
      })
    },
    getTown(id){
        this.optionTown = []
      const url = `/api/v1/towns/${id}/neighborhoods`
      this.$http.get(url).then(response => {
        const towns = response.data.data
        towns.forEach(el => {
          this.optionTown.push({ label: el.name, value: el.id })
        })
      })
    },
        getoptionStreet(id) {
      this.optionStreet = []

      const url = `/api/v1/neighborhoods/${id}/streets`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionStreet.push({ label: el.name, value: el.id })
        })
      })
    },
    addWeak() {
      this.updateWeaknessModel = false
      this.newWeakness.notes = ''
      this.newWeakness.name =''
      this.newWeakness.gender =''
      this.newWeakness.birth_date =''
      this.newWeakness.weakness =''
      this.$refs['my-modal'].show()

    },
    addreferral() {
      this.updateReferralModel = false
      this.newRefeeral.notes=''
   this.newRefeeral.name=''
this.newRefeeral.gender=''

this.newRefeeral.birth_date=''
this.newRefeeral.sub_service_id=''
this.newRefeeral.main_service_id=''
      this.$refs['my-modal-referral'].show()
      
      //   id: "",
      //   notes: "",
      // });
    },
    removereferralModel(index) {
      this.visit.referralModel.splice(index, 1)
    },
    addreferralModel() {
      this.visit.referralModel.push({
        name: '',
        gender: null,
        main_service_id: '',
        sub_service_id: '',
        birth_date: '',
        weakness: '',
        notes: '',
      })
    },
    formSubmitted() {
      // console.log("bb",this.visit)
      this.$http
        .post('/api/v1/orv/store-visit-transaction', this.visit)
        .then(res => {
          // console.log("bb",res)
          this.$swal({
            title: '',
            text: `تم اضافة  
               
         `,
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    getFamilyStatus() {
      this.$http.get('/api/v1/family_statuses').then(response => {
        this.FamilyStatus = response.data.data
      })
      this.$http.get('/api/v1/orv/family-needs').then(response => {
        this.optionlabel = response.data.family_needs
      })
      
      this.$http.get('/api/v1/residence_types').then(response => {
        this.ResidenceTypes = []
        const towns = response.data.data
towns.forEach(el => {
  this.ResidenceTypes.push({name: el.name, value: el.id })
//  console.log(el)
})
})
    
      this.$http.get('/api/v1/weaknesses').then(response => {
        // console.log("orv/family-visit-weakness", response);
        this.optionsWeakAll = response.data.data.weaknesses
      })
      this.optionsWeak = [];
     
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let subweak = res.data.data.sub_weaknesses;
         this.subWeakness= res.data.data.sub_weaknesses;
        let Weak = res.data.data.weaknesses;
        Weak.forEach((el) => {
          let obj = {};
          obj.children = [];
          obj.label = el.name;
          obj.id = el.id;
          //console.log("j", obj);
          subweak.forEach((ele, index) => {
            if (el.id == ele.parent_id) {
              obj.children.push({ label: subweak[index].name, id: ele.id });
              //console.log("SS", obj.children);
            }
          });
          this.optionsWeak.push(obj);
      //console.log(obj);

        });
      //  console.log(this.optionsWeak);
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            //     if(this.visit.weaknessModel.length ==0){
            //       this.$swal({
            //     title: "",
            //     text: `يجب ان تقوم باضافة نقطة ضعف`,
            //     icon: "error",
            //     confirmButtonText: "موافق",
            //     customClass: {
            //       confirmButton: "btn btn-primary",
            //     },
            //     buttonsStyling: false,
            // });
            //     }
            //     else{
            //       resolve(true);
            //     }
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    addnewWeakness() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addPersonWek()
            this.$refs['my-modal'].hide()
          }
        })
      })
    },
    addPersonWek(i) {
      const data = {
name: this.newWeakness.name,
gender:this.newWeakness.gender,
notes:this.newWeakness.notes,
weakness:this.newWeakness.weakness,
birth_date:this.newWeakness.birth_date
      };
      this.visit.weaknessModel.push({...data})
      console.log(this.visit.weaknessModel)

this.visit.weaknessModel.forEach((x,i)=> x.weak_id = i)
console.log(this.visit.weaknessModel)

    },
    // <template               v-if="visit.weaknessModel.length > 0"               #cell(weakness)="data"             >               {{ optionsWeakAll.find((el)=> el.id==data.item.weakness).name }}             </template>
    addnewReferral() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addPersonRef()
            this.$refs['my-modal-referral'].hide()
          }
        })
      })
    },

    addPersonRef(i) {
            const data = {
name: this.newRefeeral.name,
gender:this.newRefeeral.gender,
notes:this.newRefeeral.notes,
birth_date:this.newRefeeral.birth_date,
sub_service_id:this.newRefeeral.sub_service_id,
main_service_id:this.newRefeeral.main_service_id
      };
this.visit.referralModel.push({...data})


this.visit.referralModel.forEach((x,i)=> x.serv_id = i)

     
    },
updateBenWek(updatedWeakness) {
//  console.log(updatedWeakness)
//  console.log(updatedWeakness.weak_id)
  const data = {
name: this.newWeakness.name,
gender:this.newWeakness.gender,
notes:this.newWeakness.notes,
weakness:this.newWeakness.weakness,
birth_date:this.newWeakness.birth_date,
weak_id : this.newWeakness.weak_id
      };
      // console.log(data)
      this.$set(this.visit.weaknessModel, updatedWeakness.weak_id, { ...data });
  // console.log(this.visit.weaknessModel)
    this.$refs['my-modal'].hide();
    
  
    },
    updateReferral(updated) {
//  console.log(updated)
//  console.log(updated.serv_id)
  const data = {
name: this.newRefeeral.name,
gender:this.newRefeeral.gender,
notes:this.newRefeeral.notes,
birth_date:this.newRefeeral.birth_date,
sub_service_id:this.newRefeeral.sub_service_id,
main_service_id:this.newRefeeral.main_service_id,
serv_id : this.newRefeeral.serv_id
      };
      // console.log(data)
      this.$set(this.visit.referralModel, updated.serv_id, { ...data });
  // console.log(this.visit.referralModel)
    this.$refs['my-modal-referral'].hide();
    
  
    },
    deleteWeakness(item){
 // Use Vue.delete to remove the item from the array
 this.$delete(this.visit.weaknessModel, item.weak_id);

// Update the view to reflect the changes immediately
this.$forceUpdate(); // Force a re-render to reflect changes immediately
    },
        deleteRefferal(item){
 // Use Vue.delete to remove the item from the array
 this.$delete(this.visit.referralModel, item.serv_id);

// Update the view to reflect the changes immediately
this.$forceUpdate(); // Force a re-render to reflect changes immediately
    },
    showWeakness(item) {
      // console.log(item)
      this.$refs['my-modal'].show()
      this.updateWeaknessModel = true
      this.newWeakness = {...item}
    
    },
    showReferral(item) {
      // console.log(item)
      this.$refs['my-modal-referral'].show()
      this.updateReferralModel = true
      this.newRefeeral = {...item}
    
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    selectDetails(item){
// console.log(item)
// console.log(this.visit.weaknessModel)
let data=this.visit.weaknessModel.find(el=>el.name==item)
// console.log(this.visit.weaknessModel)
// console.log(data)
this.newRefeeral.birth_date=data.birth_date
this.newRefeeral.gender=data.gender
    }
  },
}
</script>
<style lang="scss">
label {
  color: #5e5873;
  font-size: 0.9rem !important;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>